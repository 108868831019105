exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-us-index-tsx": () => import("./../../../src/pages/contactUs/index.tsx" /* webpackChunkName: "component---src-pages-contact-us-index-tsx" */),
  "component---src-pages-employer-index-tsx": () => import("./../../../src/pages/employer/index.tsx" /* webpackChunkName: "component---src-pages-employer-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pricing-index-tsx": () => import("./../../../src/pages/pricing/index.tsx" /* webpackChunkName: "component---src-pages-pricing-index-tsx" */),
  "component---src-pages-privacy-terms-index-tsx": () => import("./../../../src/pages/privacy-terms/index.tsx" /* webpackChunkName: "component---src-pages-privacy-terms-index-tsx" */),
  "component---src-pages-purpose-index-tsx": () => import("./../../../src/pages/purpose/index.tsx" /* webpackChunkName: "component---src-pages-purpose-index-tsx" */),
  "component---src-pages-school-index-tsx": () => import("./../../../src/pages/school/index.tsx" /* webpackChunkName: "component---src-pages-school-index-tsx" */),
  "component---src-pages-student-index-tsx": () => import("./../../../src/pages/student/index.tsx" /* webpackChunkName: "component---src-pages-student-index-tsx" */)
}

